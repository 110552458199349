<template>
	<div class="newsDetails">
		<div class="header"></div>
		<div class="content">
			<div class="inner">
				<!-- 面包屑导航区域 -->
				<!-- <el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/newsAndTrends' }"
						>新闻资讯</el-breadcrumb-item
					>
					<el-breadcrumb-item>新闻内容</el-breadcrumb-item>
				</el-breadcrumb> -->
				<div class="content-text" v-show="!isEmpty">
					<div class="content-title">
						{{ data.title }}
					</div>
					<div class="content-txt">
						<time>{{ data.time }}</time>
						<!-- <span>编辑人：{{ data.editor }}</span> -->
					</div>
					<quill-editor
						v-model="content"
						id="textCenter"
						ref="myQuillEditor"
					>
					</quill-editor>
					<!-- <div id="textCenter"></div> -->
				</div>
				<div class="empty" v-show="isEmpty">
					<img v-lazy="nullImg" :key="nullImg" alt="请求失败" />
					<span>网络开小差了,请求失败</span>
				</div>
			</div>
		</div>
		<div class="left"></div>
		<div class="right"></div>
	</div>
</template>

<script>
import { getNewsDetails } from '@/network/home/index'
export default {
	name: 'newsDetails',
	data() {
		return {
			nullImg: window.imgUrl + '/04_null.png',
			data: '',
			content: '',
			isEmpty: true,
			options: [],
		}
	},
	watch: {},
	components: {},
	computed: {
		editor() {
			return this.$refs.myQuillEditor.quill
		},
	},
	mounted() {
		if (this.$route.query.id) {
			this.j_getNewDetails(this.$route.query.id)
		}
	},
	methods: {
		init() {
			let htmlWidth = document.querySelector('html').offsetWidth
			let contentWidth = ''
			if (htmlWidth > 768) {
				contentWidth = htmlWidth * 0.66
			} else {
				contentWidth = htmlWidth * 0.9
			}
			let imgAll = document
				.getElementById('textCenter')
				.querySelectorAll('img')
			for (let i = 0; i < imgAll.length; i++) {
				imgAll[i].onload = function () {
					if (imgAll[i].clientWidth > contentWidth) {
						imgAll[i].style.width = '100%'
						imgAll[i].style.display = 'block'
					}
				}
			}
		},
		j_getNewDetails(id) {
			return new Promise((resolve, reject) => {
				getNewsDetails(id).then((res) => {
					if (res.data.code == 200) {
						this.isEmpty = false
						this.data = res.data.data
						let dataContent = res.data.data.content
						dataContent = dataContent
							.replace(new RegExp('{', 'g'), '11')
							.replace(new RegExp('}', 'g'), '11')
							.replace(new RegExp('\\$', 'g'), '22')
						let txt = '2211' + '.' + '11'
						let rContent = dataContent.replace(
							new RegExp(txt, 'g'),
							window.baseUrl
						)
						// this.content = res.data.data.content;
						document.getElementById('textCenter').innerHTML =
							rContent
						this.$nextTick(() => {
							this.init()
							document
								.getElementById('textCenter')
								.getElementsByClassName('ql-editor')[0]
								.setAttribute('contenteditable', false)
						})
						resolve()
					} else {
						reject()
					}
				})
			})
		},
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.newsDetails {
	.header {
		width: 100%;
		height: 0.64rem;
		background-color: #232738;
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}

	.content {
		margin-top: 0.6rem;
		padding: 0.8rem 0;
		min-height: 4rem;
		::v-deep .el-breadcrumb {
			font-size: 0.18rem;
			margin-bottom: 0.4rem;
		}
		.content-text {
			font-size: 0.16rem;
			.content-title {
				font-size: 0.52rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 500;
				color: #333333;
				line-height: 0.52rem;
				text-align: center;
			}
			.content-txt {
				text-align: center;
				font-size: 0.16rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				line-height: 0.24rem;
				margin: 0.4rem 0;
				time {
					margin-right: 0.2rem;
				}
			}
			#textCenter {
				text-align: left;
				text-indent: 2em;
				line-height: 130%;
				overflow: hidden;
				::v-deep .ql-editor {
					overflow: hidden;
				}
				p {
					width: 100%;
					img {
						width: 80%;
						text-align: left;
					}
				}
				.ql-toolbar {
					display: none !important;
				}
				.ql-container {
					border: 0;
				}
			}
		}
		.empty {
			width: 45%;
			height: 45%;
			margin: auto;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				display: block;
			}
			span {
				width: 100%;
				display: inline-block;
				position: absolute;
				bottom: 20%;
				font-size: 0.18rem;
				color: #666;
				text-align: center;
			}
		}
	}
}
img {
	display: block;
}
@media screen and (max-width: 768px) {
	.newsDetails {
		margin-bottom: 40px;
		.header {
			height: 44px;
		}
		.left,
		.right {
			width: 16px;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
		.content {
			margin-top: 32px;
			padding: 0;
			.content-text {
				font-size: 14px;
				.content-title {
					font-size: 18px;
					line-height: 26px;
				}
				.content-txt {
					font-size: 12px;
					line-height: 20px;
					margin: 12px 0;
					span {
						display: none;
					}
				}
				#textCenter {
					::v-deep .ql-editor {
						padding: 0;
					}
					img {
						width: 80%;
						height: 80%;
						text-align: center;
						margin: 10px 0;
					}
					.ql-video {
						width: 80%;
						height: 80%;
						text-align: center;
					}
				}
			}
		}
	}
}
</style>
